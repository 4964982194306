import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { makeStyles } from '@mui/styles'
import LineChart from '../components/LineChart';
import { Button } from 'react-bootstrap';
import BarchartComponents from '../components/BarchartComponents';
import PieChartComponents from '../components/PieChartComponents';
import Table from 'react-bootstrap/Table';
import { AiOutlineRise } from 'react-icons/ai'
import { HiChartPie } from 'react-icons/hi'
import { MdOutlineBarChart } from 'react-icons/md'
import localForAgeService from '../Services/localForAgeService';
import Select from 'react-select';
import CustomTable from '../ReuseComponent/CustomTable';



const ChartDetails = ({data,labels,count,headers}) => {
    console.log("labels 9657149461",labels);
    console.log("count 9657149461",count);
    console.log("count 9657149461 data",data);
    const [certificatesData,setCertificatesData] = useState()



    const styles = useStyles()

    const [displayChart, setDisplayChart] = useState('Line')
    const getItem = async (key) => {
        try {
            const value = await localForAgeService.getItem(key);
            console.log("Getting Value detttt",value);
            setCertificatesData(value)
        } catch (err) {
            // This code runs if there were any errors.
            console.log(err);
        }
    }
    useEffect(()=>{
        getItem("DeathCertificates")
    },[])


    return (
        <motion.div className={styles.mainContainer}>
            <motion.div className={styles.titleChartDiv}>
                <motion.div className={styles.titleDiv}>
                    <motion.label>Data asset</motion.label>
                    <motion.h2>Bank customers</motion.h2>
                    <motion.p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar, dui in bibendum consectetur, massa arcu convallis dui, nec scelerisque lectus dolor nec sapien. Praesent vel vehicula nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar, dui in bibendum consectetur, massa arcu convallis dui, nec scelerisque lectus dolor nec sapien. Praesent vel vehicula nulla. Lorem ipsum dolor sit amet, ddsgg consectetur adipiscing elit. nec scelerisque lectus dolor nec sapien. Praesent vel vehicula nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar, dui in bibendum consectetur</motion.p>
                </motion.div>
                <motion.div className={styles.chartDiv}>
                    <motion.div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <motion.div style={{ width:'50%',fontSize:'18px',fontWeight:'600' }}>
                            Lorem ipsum dolor sit amet
                        </motion.div>
                        <motion.div style={{ width:'50%',display:'flex',justifyContent:'flex-end' }}>
                            <Button className={ displayChart === 'Line' ? styles.activeButton : styles.button } onClick={() => setDisplayChart('Line')} >
                                <AiOutlineRise />    Line
                            </Button>
                            <Button className={ displayChart === 'Pie' ? styles.activeButton : styles.button  } onClick={() => setDisplayChart('Pie')}>
                                <HiChartPie />      Pie
                            </Button>
                            <Button className={ displayChart === 'Bar' ? styles.activeButton : styles.button  } onClick={() => setDisplayChart('Bar')} >
                                <MdOutlineBarChart />       Bar
                            </Button>
                        </motion.div>
                    </motion.div>
                    <motion.div style={{ maxHeight: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {displayChart === 'Line' && labels.length>0 && count.length>0 ? <LineChart labels={labels} count = {count} /> :
                            displayChart === 'Bar' ? <BarchartComponents labels={labels} count = {count} /> : <PieChartComponents labels={labels} count = {count} />}
                    </motion.div>
                </motion.div>
            </motion.div>
            <motion.div style={{ width: '95%' }}>
                
                <CustomTable headers={headers} data={data} /> 
            </motion.div>
        </motion.div>
    )
}

const useStyles = makeStyles({
    mainContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        alignItems: 'center'
    },
    titleChartDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    titleDiv: {
        width: '45%',
        padding: '2%',
        margin: '2%',
        backgroundColor: 'white',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column'
    },
    chartDiv: {
        width: '55%',
        display: 'flex',
        flexDirection: 'column',
        padding: '2%',
        margin: '2%',
        backgroundColor: 'white',
        borderRadius: '8px',
    },
    button: {
        margin:'1%',
        backgroundColor:'white',
        color:'rgba(58, 0, 85, 1)',
        borderColor:'rgba(58, 0, 85, 1)',
        "&:hover": {
            backgroundColor:'rgba(58, 0, 85, 1)',
            color:'white',
            borderColor:'white'
        },
    },
    activeButton:{
        margin:'1%',
        backgroundColor:'rgba(58, 0, 85, 1)',
        color:'white',
        borderColor:'white',
        "&:hover":{
            backgroundColor:'rgba(58, 0, 85, 1)',
            color:'white',
            borderColor:'white',
        }
    }
})

export default ChartDetails
