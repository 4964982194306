import { makeStyles } from '@mui/styles'
import React from 'react'
import { motion } from 'framer-motion'
import profile from '../assets/images/tiger (1).png'
import { BiSolidChevronDown } from 'react-icons/bi'
import { Dropdown } from 'react-bootstrap'

function Topbar() {

  const styles = useStyles()


  return (
    <motion.div className={styles.mainContainer}>
      <motion.div animate={{ marginLeft:'2%' }}>
        <motion.h3>
          KPI's Dashboard
        </motion.h3>
      </motion.div>
      <motion.div className={styles.logo}>
        <motion.div className={styles.profile}>
          <img src={profile} className={styles.profilePic} />
          <Dropdown>
          <Dropdown.Toggle style={{ color:'black',backgroundColor:'transparent',border:'none' }}>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    width: '100%',
    height: '100%'
  },
  logo: {
    width: '12%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  profile: {
    width: '70%',
    height: '80%',
    backgroundColor: 'rgba(58, 0, 85, 0.1)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  profilePic: {
    width: '50%',
    height: '80%',
    borderRadius: '50%',
    border: '1px solid black'
  }
})

export default Topbar