import React from "react";
import { motion } from "framer-motion";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//     labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//     datasets: [
//       {
//         label: '# of Votes',
//         data: [12, 19, 3, 5, 2, 3],
//         backgroundColor: [
//           'rgba(255, 99, 132, 0.2)',
//           'rgba(54, 162, 235, 0.2)',
//           'rgba(255, 206, 86, 0.2)',
//           'rgba(75, 192, 192, 0.2)',
//           'rgba(153, 102, 255, 0.2)',
//           'rgba(255, 159, 64, 0.2)',
//         ],
//         borderColor: [
//           'rgba(255, 99, 132, 1)',
//           'rgba(54, 162, 235, 1)',
//           'rgba(255, 206, 86, 1)',
//           'rgba(75, 192, 192, 1)',
//           'rgba(153, 102, 255, 1)',
//           'rgba(255, 159, 64, 1)',
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

const PieChartComponents = ({ labels, count }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: count,
        borderColor: "rgb(255, 99, 132)",
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 0, 191, 1)",
          "rgba(127, 17, 224, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(179, 0, 80, 0.2)",
          "rgba(255, 0, 115, 0.2)",
          "rgba(255, 0, 191, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 0, 191, 1)",
          "rgba(127, 17, 224, 1)",
          "rgba(255, 255, 0, 1)",
          "rgba(179, 0, 80, 1)",
          "rgba(255, 0, 115, 1)",
          "rgba(255, 0, 191, 1)",
        ],
        borderWidth: 1,
      },
      // {
      //   label: 'Dataset 2',
      //   data: [-900,-500,0,100,200,500,-340,5000],
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };
  return <Pie data={data} style={{ width: "50%", height: "50%" }} />;
};

export default PieChartComponents;
