import React from "react";
import Table from "react-bootstrap/Table";

const CustomTable = ({ headers, data }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 ? (
          data?.map((item, index) => (
            <tr key={index}>
              {/* {headers.map((header, headerIndex) => (
          <td key={headerIndex}>
            {item[header.toLowerCase()] !== undefined ? item[header.toLowerCase()] : item.count}
          </td>
        ))} */}
              <td>{index + 1}</td>
              <td>{item.month ? item.month : item.date}</td>
              <td>{item.count}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={headers.length}>No data available</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CustomTable;
