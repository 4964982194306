import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Topbar from './Topbar';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';

function MainLayout() {

  const styles = useStyles()
  
  return (
    <motion.div className={styles.mainContainer}>
      <motion.div animate={{ width: '20%' }}>
        <Sidebar />
      </motion.div>
      <motion.div animate={{ width: '100%',}} style={{ display: 'flex', flexDirection: 'column',scrollbarWidth:'none' }} >
        <motion.div style={{ minHeight:'10%' }}>
          <Topbar />
        </motion.div>
        <motion.div className={styles.outlet}>
          <Outlet />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    height:'100vh',
    backgroundColor: '#f7f7f7'
  },
  outlet:{
    width:'100%',
    overflow:'scroll'
  }
})

export default MainLayout