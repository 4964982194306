import React from 'react'
import { motion } from 'framer-motion'
import { makeStyles } from '@mui/styles'
import BarchartComponents from '../components/BarchartComponents';
import LineChart from '../components/LineChart';

function Dashbord() {

  const styles = useStyles()

  return (
    <motion.div className={styles.mainContainer}>
      Dashboard
      {/* <motion.div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <motion.div className={styles.charts} >
          <LineChart />
        </motion.div>
        <motion.div className={styles.charts}>
          <BarchartComponents />
        </motion.div>
      </motion.div>
      <motion.div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <motion.div className={styles.charts}>
          <LineChart />
        </motion.div>
        <motion.div className={styles.charts}>
          <BarchartComponents />
        </motion.div>
      </motion.div>
      <motion.div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <motion.div className={styles.charts}>
          <LineChart />
        </motion.div>
        <motion.div className={styles.charts}>
          <BarchartComponents />
        </motion.div>
      </motion.div>
      <motion.div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <motion.div className={styles.charts}>
          <LineChart />
        </motion.div>
        <motion.div className={styles.charts}>
          <BarchartComponents />
        </motion.div>
      </motion.div> */}
    </motion.div>
  )
}

const useStyles = makeStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    overflowY:'scroll'
  },
  lineChart: {
    width: '40%',
    margin: '2%'
  },
  charts: {
    width: '48%',
    margin: '2%',
    height: 300,
  }
})

export default Dashbord