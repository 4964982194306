import React, { useEffect, useState } from "react";
import axios from "axios";
import ChartDetails from "./ChartDetails";
import { motion } from "framer-motion";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import localForAgeService from "../Services/localForAgeService";
import CryptoJS from "crypto-js";

const options = [
  { value: "", label: "" },
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const options1 = [
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  // Add more years here...
];
const headers = ["#", "Month", "Count"];

const BirthCertificate = () => {
  const [state, setState] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("2023");
  const [loading, setLoading] = useState(true);

  const styles = useStyles();

  const getDeathCertificateData = async (year, month) => {
    try {
      const dateParam = year ? (month ? `${year}-${month}` : year) : "2023";
      console.log("dateParam", dateParam);

      const key = `${year}-${month}`;
      const encryptedData = localForAgeService.getItem(
        `BIRTH_CERTIFICATE_DOWNLOAD_COUNT${key}`
      );
      encryptedData.then((data) => {
        console.log("data", data);
        if (data !== null) {
          // Data is present in cache, decrypt and use it
          const bytes = CryptoJS.AES.decrypt(data, "secret_key"); // Replace 'secret_key' with your encryption key
          const responseData = bytes.toString(CryptoJS.enc.Utf8);
          setState(responseData);
          setLoading(false);
        } else {
          console.log("api call");
          console.log("month", dateParam);

          // Construct the API URL
          // const url = `http://localhost:4002/v1/kpi/registration/calculateBirthCertificateCountYearly${
          //   month ? "" : "Yearly"
          // }?date=${dateParam}`;
          const url = `http://3.108.43.215:4002/v1/kpi/registration/calculateBirthCertificateCountYearly${
            month ? "" : "Yearly"
          }?date=${dateParam}`;

          // Make the API request using Axios
          axios
            .post(url)
            .then((response) => {
              console.log("API response", response);

              // Extract the data from the response
              const responseData = response?.data?.response;

              // Encrypt and cache the retrieved data
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(responseData),
                "secret_key"
              ).toString();
              localForAgeService.setItem(
                `BIRTH_CERTIFICATE_DOWNLOAD_COUNT${key}`,
                encryptedData
              );

              // Set the state with the response data
              setState(responseData);
              setLoading(false);
            })
            .catch((error) => {
              // setState(error)
              console.error("API request failed:", error);
              // Handle the error here, e.g., show an error message or retry the request.
              // You can also rethrow the error if you want it to be handled higher up in your code.
            });
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getItem = async (key) => {
    console.log("keyss", key);
    try {
      const value = await localForAgeService.getItem(key);
      console.log("Getting Value", value);
      setState(value);
      return value;
      // setActiveItem(value)
    } catch (err) {
      // This code runs if there were any errors.
      console.log(err);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSelectChange1 = (selectedOption) => {
    setSelectedOption1(selectedOption);
  };
  const decryptData = (encryptedData, key) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key); // Replace 'secret_key' with your encryption key
    console.log("BYTES", bytes);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };

  useEffect(() => {
    const year = selectedOption1.value;
    const month = selectedOption.value;
    const key = `${year}-${month}`;

    const encryptedData = localForAgeService.getItem(
      `BIRTH_CERTIFICATE_DOWNLOAD_COUNT${key}`
    );
    console.log("encryptedData", encryptedData);
    encryptedData.then((cachedData) => {
      console.log("cachedData 123", cachedData);
      if (cachedData !== null) {
        const cachedDataa = decryptData(cachedData, "secret_key"); // Replace 'secret_key' with your encryption key
        console.log("cachedData 965714 ", cachedDataa);
        setState(cachedDataa);
        setLoading(false);
      } else {
        console.log("else");
        // Data not found, make an API request
        getDeathCertificateData(year, month);
      }
    });
  }, [selectedOption, selectedOption1]);
  console.log("state",state);

  return (
    <motion.div className={styles.mainContainer}>
      <motion.div className={styles.titleChartDiv}>
        <motion.div className={styles.titleDiv}>
          <motion.label>Data asset</motion.label>
        </motion.div>
        <motion.div className={styles.chartDiv}>
          <Select
            className={styles.customSelect}
            value={selectedOption1}
            onChange={handleSelectChange1}
            options={options1}
            placeholder="Select Year"
          />
          <Select
            className={styles.customSelect}
            value={selectedOption}
            onChange={handleSelectChange}
            options={options}
            placeholder="Select Month"
          />
        </motion.div>
      </motion.div>
      {Array.isArray(state) && state.length > 0 ? ( // Check if state is an array and not empty
        <ChartDetails
          labels={state.map((item) => item.month || item.date)}
          count={state.map((item) => item.count)}
          data={state}
          headers={headers}
        />
      ) : (
        <div>Loading or empty state message</div> // You can display a loading message or handle this case as needed
      )}
    </motion.div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    alignItems: "center",
  },
  titleChartDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  titleDiv: {
    width: "45%",
    padding: "2%",
    margin: "2%",
    backgroundColor: "white",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  chartDiv: {
    width: "40%",
    display: "flex",
    flexDirection: "row",
    padding: "2%",
    margin: "2%",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  customSelect: {
    marginLeft: "20px",
  },
});

export default BirthCertificate;
