import React from 'react'
import localforage from 'localforage'

const localForAgeService =  {
  
     setItem : async (key,value) => {
        console.log("seting ",key);
        localforage.setItem(key, value).then(function (value) {
            // Do other things once the value has been saved.
            console.log("Setting Item",key,value);
        }).catch(function(err) {
            // This code runs if there were any errors
            console.log(err);
        });
    },
     setLabel : async (key,value) => {
        localforage.setItem(key, value).then(function (value) {
            // Do other things once the value has been saved.
            console.log("Setting graph Label",value);
        }).catch(function(err) {
            // This code runs if there were any errors
            console.log(err);
        });
    },
    
      getItem : async (key) => {
        try {
            const value = await localforage.getItem(key);
            return value
        } catch (err) {
            // This code runs if there were any errors.
            console.log(err);
        }
    }
}



export default localForAgeService