import './App.css';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom'
import Dashbord from './pages/Dashbord';
import Sidebar from './components/Sidebar';
import { useEffect } from 'react';
import MainLayout from './components/MainLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChartDetails from './pages/ChartDetails';
import localforage from 'localforage';
import DeathCertifiacte from './pages/DeathCertifiacte';
import BirthCertificate from './pages/BirthCertificate';

function App() {

  localforage.setDriver([
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    localforage.WEBSQL,
  ]);

  localforage.config({
    driver: [localforage.INDEXEDDB,
             localforage.WEBSQL,
             localforage.LOCALSTORAGE],
    name: 'KPI-Dashboard'
});

  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path='/' element={<MainLayout />} >
              <Route path='/dashboard' element={ <Dashbord/> } />
              <Route path='/chart-details-deathCertificateData' element={<DeathCertifiacte />} />
              <Route path='/chart-details-birthCertificateData' element={<BirthCertificate />} />
            </Route>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
