import React from 'react'
import { motion } from 'framer-motion'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [-900,-500,0,100,200,500,-580],
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: [-900,-500,0,100,200,500,-340],
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

const BarchartComponents = ({labels,count}) => {
  const data = {

    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data:count,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      // {
      //   label: 'Dataset 2',
      //   data: [-900,-500,0,100,200,500,-340,5000],
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };



  return (
    <motion.div style={{ backgroundColor:'white',height:'100%',width:'100%',borderRadius:'12px',padding:'2%'  }}>
      <Bar options={options} data={data} />
    </motion.div>
  )
}

export default BarchartComponents