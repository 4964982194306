import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaSearch } from 'react-icons/fa'
import { makeStyles } from '@mui/styles';
import PMCLOGO from '../assets/images/pmc-care-logo.jpg'
import { MdDashboard } from 'react-icons/md'
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import FilterImage from '../assets/images/mi_filter.png';
import localforage, { key } from "localforage";
import localForAgeService from '../Services/localForAgeService';

function Sidebar() {
    const navigate = useNavigate()
    const styles = useStyles()

    const [activeItem, setActiveItem] = useState('')
    const [
        checkActive, setCheckActive] = useState(false)
    

    useEffect(() => {
        navigate("/dashboard")
        getItem('SidebarActive')

    }, [])

    // const setItem = async (key,value) => {
    //     localforage.setItem(key, value).then(function (value) {
    //         // Do other things once the value has been saved.
    //         console.log("Setting Item",value);
    //     }).catch(function(err) {
    //         // This code runs if there were any errors
    //         console.log(err);
    //     });
    // }

    const getItem = async (key) => {
        try {
            const value = await localForAgeService.getItem(key);
            console.log("Getting Value",value);
            setActiveItem(value)
        } catch (err) {
            // This code runs if there were any errors.
            console.log(err);
        }
    }

    const routes = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <MdDashboard />
        },
        {
            path: "/dashboard",
            name: "Home",
            icon: <MdDashboard />
        },
        {
            path: "/dashboard",
            name: "About",
            icon: <MdDashboard />
        },
    ]

    const chartsArray = [
        { name: 'Birth Certificates', value: '/chart-details-birthCertificateData' },
        { name: 'Death Certificates', value: '/chart-details-deathCertificateData' },
        { name: 'HIJ', value: 42 },
    ]

    const changeActive = (row) => {
        setActiveItem(row.name)
        navigate(row.path)
        localForAgeService.setItem('SidebarActive',row.name)
    }
    const changeActive1 = (row) => {
        setActiveItem(row.name)
        navigate(row.value);
        localForAgeService.setItem('SidebarActive',row.name)
    }

    return (
        <motion.div className={styles.mainContainer}>
            <motion.div className={styles.headerDiv}>
                <motion.div className={styles.headerLogoDiv}>
                    <img src={PMCLOGO} className={styles.headerLogo} />
                </motion.div>
                <motion.div className={styles.headerTitleDiv}>
                    KPI's Dashboard
                </motion.div>
            </motion.div>
            <motion.hr style={{ width: '90%', margin: 0, border: '0.2px solid' }} />
            <motion.div className={styles.menuDiv}>
                {
                    routes.map((row, index) => (
                        <motion.div key={index} className={activeItem === row.name ? styles.activeItem : styles.menuItem} onClick={() => changeActive(row)}>
                            <motion.div style={{ marginLeft: '10%', scale: 1.2 }}>
                                {row.icon}
                            </motion.div>
                            <motion.div style={{ marginLeft: '10%', fontWeight: '600' }}>
                                {row.name}
                            </motion.div>
                        </motion.div>
                    ))
                }
            </motion.div>
            <motion.div className={styles.searchContainer}>
                <motion.div className={styles.searchField}>
                    <InputGroup className={styles.searchBox}>
                        <InputGroup.Text>
                            <FaSearch />
                        </InputGroup.Text>
                        <FormControl style={{ width: '60%' }} placeholder='Type to Search' />
                    </InputGroup>
                    <Button className={styles.searchButton}>
                        <img src={FilterImage} className={styles.filterLogo} />
                    </Button>
                </motion.div>
                <motion.label className={styles.searchLabel}>You are limited to selecting up to 8 items</motion.label>
            </motion.div>
            <motion.div className={styles.listButtonContainer}>
                {checkActive ? <Button className={styles.SelectButton} onClick={() => setCheckActive(false)}>Submit</Button>
                    : <Button className={styles.SelectButton} onClick={() => setCheckActive(true)}>Select</Button>}
                <Button className={styles.DeSelectButton} onClick={() => setCheckActive(false)}>Deselect</Button>
            </motion.div>
            <motion.div className={styles.listContainer}>
                <motion.div className={styles.list}>
                    {
                        chartsArray.map((row, index) => (
                                // console.log("row data",row)
                            <motion.div key={index}  className={activeItem === row.name ? styles.activeItem : styles.menuItem} onClick={() => {changeActive1(row); }}>
                                {checkActive ? <input type='checkbox' className={styles.listCheckbox} /> : null}
                                <motion.label className={styles.listTitle}>{row.name}</motion.label>
                            </motion.div>
                        ))
                    }
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

const useStyles = makeStyles({
    mainContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        flexDirection: 'column',
        alignItems: 'center'
    },
    headerDiv: {
        width: '100%',
        height: '10%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    headerLogoDiv: {
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerTitleDiv: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.5rem',
        color: 'rgba(124, 31, 168, 1)',
        fontWeight: '600'
    },
    headerLogo: {
        width: '60%',
        height: '80%',
        borderRadius: '50%'
    },
    menuDiv: {
        width: '90%'
    },
    menuItem: {
        width: '100%',
        padding: '2%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid black',
        margin: '2%',
        borderRadius: '8px',
        "&:hover": {
            background: "#3A0055",
            color: 'white'
        },
    },
    activeItem: {
        width: '100%',
        padding: '2%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid black',
        margin: '2%',
        borderRadius: '8px',
        background: "#3A0055",
        color: 'white'
    },
    searchContainer: {
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    searchBox: {
        width: '80%'
    },
    searchButton: {
        width: '18%',
        backgroundColor: 'rgba(58, 0, 85, 0.1)',
        border: 'none'
    },
    filterLogo: { width: '100%', height: '90%' },
    searchField: {
        width: '100%', margin: '2%', display: 'flex', flexDirection: 'colum', justifyContent: 'space-between',
        padding: '2%',
    },
    searchLabel: {
        fontSize: '13px', color: 'rgba(191, 191, 191, 1)'
    },
    listContainer: {
        width: '90%',
        overflowY: 'auto',
        marginBottom: '1%',
        "&::-webkit-scrollbar": {
            width: '5px', /* Set the width of the scrollbar */
        },

        /* Change scrollbar thumb color */
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(58, 0, 85, 1)" /* Set the color of the scrollbar thumb (the draggable part) */
        },

        /* Change scrollbar track color */
        "&::-webkit-scrollbar-track": {
            background: "#f0f0f0" /* Set the color of the scrollbar track (the non-draggable part) */
        }

    },
    listButtonContainer: {
        width: '100%',
        display: 'flex',
        height: '4%',
        flexDirection: 'row',
        marginLeft: '8%'
    },
    list: {
        width: '100%',
        overflow: 'scoll',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
    },
    SelectButton: {
        height: '70%',
        margin: '2%',
        borderRadius: '12px',
        backgroundColor: 'rgba(58, 0, 85, 1)',
        color: 'white',
        borderColor: 'rgba(58, 0, 85, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    DeSelectButton: {
        height: '70%',
        margin: '2%',
        borderRadius: '12px',
        backgroundColor: 'white',
        color: 'rgba(58, 0, 85, 1)',
        borderColor: 'rgba(58, 0, 85, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '5%',
        padding: '4%',
        color: 'rgba(102, 112, 133, 1)',
        borderBottom: '1px solid rgba(234, 236, 240, 1)',
        "&:hover": {
            color: 'white',
            backgroundColor: 'rgba(58, 0, 85, 1)'
        }
    },
    listCheckbox: {
        scale: 1.6,
        backgroundColor: 'rgba(58, 0, 85, 1)',
        color: 'white'
    },
    listTitle: {
        marginLeft: '5%',
    }
})

export default Sidebar
